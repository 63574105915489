import React from 'react';
import styles from './banner.module.css';

function CookiesBanner( props ) {
  const {text, callback} = props;

  return (
    <section className={styles.container}>
        <div className={styles.wrapper}>
            <p>{text?.description}</p>
            <span onClick={callback}>{text?.button}</span>
        </div>
    </section>
  );
}

export default CookiesBanner;
