import React from 'react';
import styles from './nav.module.css';
import {GA_EVENTS} from "../../util/constants";
import {trackGoogleEvent} from "../../util/google-helpers";

function Nav( props ) {
  const {text, navigator, activePage} = props;
  const navigateTo = (id) => {
      const { category, action } = GA_EVENTS.ROUTING_CLICK;
      trackGoogleEvent( category, action, id );
      navigator(id);
  };

  return (
    <section className={styles.container}>
      <ul className={styles.navigation}>
        {text && text.items.map( (item, index) => {
            if ( item.external_link === "" ) {
                return (
                    item.visible === "true" &&
                    <li className={activePage === item.id ? styles.activeLink : ""}
                        onClick={()=>{navigateTo(item.id)}}
                        key={index}>
                        {item.text}
                    </li>
                );
            } else {
                return (
                    item.visible === "true" &&
                    <li className={activePage === item.id ? styles.activeLink : ""} key={index}>
                        <a href={item.external_link} target="_blank" rel="noopener noreferrer">{item.text}</a>
                    </li>
                );
            }
        })}
      </ul>
    </section>
  );
}

export default Nav;
