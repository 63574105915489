import React, {useEffect} from 'react';
import styles from './terms.module.css';
import {trackGooglePage} from "../../util/google-helpers";

function Terms( props ) {
  const {text} = props;

    useEffect(() => {
        trackGooglePage('Terms & Conditions Page');
    }, []);

  return (
    <section className={styles.container}>
      <p dangerouslySetInnerHTML={{__html: text}}/>
    </section>
  );
}

export default Terms;
