import React, { useEffect, useState } from 'react';
import useCMS from '@telescope/react-hooks/useCMS';
import api from './util/api';
import {COOKIES_ACCEPTANCE_NAME, COOKIES_LOGGED_USER, DEFAULT_WID, GA_EVENTS, TERMS_WID, VIEWS} from './util/constants';

import Closed from './components/closed';
import Landing from './components/landing';
import Event from './components/event';
import pym from 'pym.js';
import styles from './App.module.css';

import * as googleHelpers from './util/google-helpers';

import Div100vh from 'react-div-100vh';
import Nav from './components/nav';
import Agenda from './components/agenda';
import Terms from './components/terms';
import { createCookie, readCookie } from './util/helpers';
import CookiesBanner from './components/cookiesBanner';
import {trackGoogleEvent} from "./util/google-helpers";

new pym.Child({ polling: 500 });

function App() {
  const [ userData, setUserData ] = useState(false);
  const [ showCookieBanner, setCookieBannerVisibility] = useState(true);
  const [ eventData, setEventData ] = useState(null);
  const [ isReady, setIsReady ] = useState(false);
  const [ pollingFrequency, setPollingFrequency ] = useState(15000);
  const [ activePage, setActivePage ] = useState(VIEWS.EVENT);

  const qsps = api.getQSPStorage();
  const [ widget ] = useCMS(qsps.wid || DEFAULT_WID, { sid: qsps.sid, pollingFrequency, env: "uk" });
  const [ terms_widget ] = useCMS(TERMS_WID, { sid: qsps.sid, pollingFrequency, env: "uk" });

  const appSettings = widget ? widget.settings : {};
  const windowStatus = widget ? parseInt(widget.settings.window_status, 10) : 0;

  if(appSettings.google_analytics && !googleHelpers.isInitialized()) {
    googleHelpers.initializeGoogleAnalytics(appSettings.google_analytics);
  }

  useEffect(() => {
    if (widget) {
      if(!isReady) setIsReady(true);
      setPollingFrequency(widget.settings.cms_poll_rate * 1000);
    }
  }, [ widget, isReady ]);

  useEffect(() => {
    if (userData) {
      api.fetchFundCms().then(response => {
        if (response && response.snapshot) {
          const data = response.snapshot.data;
          setEventData({
            data: data.data,
            text: data.text
          });
          setActivePage(VIEWS.EVENT);
        }
      });
    }
  }, [userData]);

  useEffect(()=>{
    const cookiesAcceptance = readCookie(COOKIES_ACCEPTANCE_NAME);
    const userLogged = readCookie(COOKIES_LOGGED_USER);
    if (cookiesAcceptance && cookiesAcceptance === "1") {
      setCookieBannerVisibility(false);
    }

    if (userLogged && userLogged === "true") {
      setUserData(true);
    }
  }, []);

  const handleCookiesAcceptance = () => {
    createCookie(COOKIES_ACCEPTANCE_NAME, "1");
    setCookieBannerVisibility(false);
  };

  const navigateTo = (pageId) => {
    const { category, action } = GA_EVENTS.ROUTING_CLICK;
    trackGoogleEvent( category, action, pageId );
    setActivePage(pageId);
  };

  const {
    landing = {},
    closed = {},
    agenda = {}
  } = widget ? widget.text : {};

  let content;

  if( !isReady ) {
    content = <div> Loading... </div>;
  } else if ( !windowStatus && activePage !== VIEWS.AGENDA && activePage !== VIEWS.TERMS ) {
    content = <Closed copy={ closed } />;
  } else if (activePage === VIEWS.AGENDA) {
    content = <Agenda text={agenda} />
  } else if (activePage === VIEWS.TERMS) {
    content = <Terms text={terms_widget.data.document} />
  } else if (!userData) {
    content = <Landing copy={ landing }
                       appSettings={ appSettings }
                       setUserData={ setUserData }
                       navigator={setActivePage} />;
  } else if (eventData) {
    content = <Event text={eventData?.text.event} navigator={setActivePage} />;
  } else {
    content = "";
  }

  return (
    <Div100vh className={ styles.app }>

      <header>
        <div className={ styles.header_content }>
          <h1>{widget?.text.header.title}</h1>
          <p>{widget?.text.header.subtitle}</p>
          <div className={styles.partners}>
            {widget?.text.header.logos.map(
              (logo, index) => {
               return (
                   <a key={index} href={logo.url} target="_blank" rel="noopener noreferrer">
                     <img alt="" src={logo.image} />
                   </a>)
              }
            )}
          </div>
        </div>
      </header>
      <Nav text={widget?.text.navigation} navigator={setActivePage} activePage={activePage} />

      <div className={ styles.container }>
        { content }
      </div>

      <footer className={styles.footer_container }>
        <div className={ styles.footer_group }>
          {
            widget?.text.footer.link_1.active === "true" && <span onClick={()=>{navigateTo(VIEWS.TERMS)}}>{widget?.text.footer.link_1.text}</span>
          }
        </div>
      </footer>

      {showCookieBanner && <CookiesBanner text={widget?.text.cookies_banner} callback={handleCookiesAcceptance} />}

    </Div100vh>
  );
}

export default App;
