import React, {useEffect} from 'react';
import styles from './event.module.css';
import {trackGoogleEvent, trackGooglePage} from "../../util/google-helpers";
import {GA_EVENTS} from "../../util/constants";

function Event(props) {
    const {text, navigator} = props;

    useEffect(() => {
        trackGooglePage('Event Page');
    }, []);

    const navigateTo = (id) => {
        const { category, action } = GA_EVENTS.ROUTING_CLICK;
        trackGoogleEvent( category, action, id );
        navigator(id);
    };

    return (
        <section className={styles.container}>
            <div className={styles.iframes_container}>
                <div className={ text.chat.active === "true" ? styles.video_container : styles.full_video_container }>
                    <iframe src={text.video_url} title="embed_video" className={styles.video}/>
                </div>
                {text.chat.active === "true" &&
                <div className={ styles.chat_container}>
                    <iframe src={text.chat.url} title="embed_chat" className={styles.chat}/>
                </div>}
            </div>
            <div className={styles.info_container}>
                <span>{text.info.text_1}</span>
                <span className={ styles.info_link} onClick={()=>navigateTo(text.info.page_id)}>{text.info.text_link}</span>
                <span>{text.info.text_2}</span>
            </div>
        </section>
    );
}

export default Event;
