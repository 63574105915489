import React, {useEffect} from 'react';
import styles from './agenda.module.css';
import {trackGooglePage} from "../../util/google-helpers";

function Agenda( props ) {
  const {text} = props;

    useEffect(() => {
        trackGooglePage('Agenda Page');
    }, []);

  return (
    <section className={styles.container}>
      <h1 className={styles.title}>{text.schedule_title}</h1>
      <div className={styles.agenda_table}>
        <div className={styles.agenda_table_header}>
          <span>{text.time_table.headers.col_2}</span>
        </div>
        <div className={styles.agenda_table_body}>
          {text.time_table.items.map((item, index) => {
            if (item.active === "true") {
              return <div key={index} className={styles.agenda_table_body_item}>
                <div></div>
                <div>
                  <span dangerouslySetInnerHTML={{ __html: item.col_2.title }}></span>
                  <span dangerouslySetInnerHTML={{ __html: item.col_2.description }}></span>
                </div>
              </div>
            }
          })}
        </div>
      </div>
      <h1 className={styles.title}>{text.speakers_title}</h1>
      <div className={styles.agenda_speakers_table}>
        {text.speakers.map((speaker, index) => {
          if (speaker.active === "true") {
            return <div key={index} className={styles.agenda_speakers_table_speaker}>
              <img src={speaker.image} />
              <div>
                <span>{speaker.title}</span>
                <span>{speaker.description}</span>
              </div>
            </div>
          }
        })}
      </div>
      <h1 className={styles.title}>{text.partners.title}</h1>
      <p className={styles.partners_description} dangerouslySetInnerHTML={{__html: text.partners.description}}/>
      <div className={styles.partners}>
        {text.partners.logos.map(
          (logo, index) => {
              return (
                  <a key={index} href={logo.url} target="_blank" rel="noopener noreferrer">
                      <img alt="" src={logo.image} />
                  </a>);
          }
        )}
      </div>
    </section>
  );
}

export default Agenda;
