interface GooglePageObject {
  type: string;
  payload: string;
}

interface GoogleEventObject {
  type: string;
  payload: { category: string; action: string; label: string };
}

let _isInitialized = false;
let googleQueue:Array<GoogleEventObject | GooglePageObject> = [];

const GA_TYPES = {
  EVENT: 'event',
  PAGE: 'page_view'
}

const GA_LOCAL_STORAGE_KEY = 'marblegate';

(window as any).gtag = function() {
  (window as any).dataLayer.push(arguments);
};

const _installGA = (trackingId:string) => {
  const scriptId = 'ga-gtag';

  if (document.getElementById(scriptId)) return;

  const { head } = document;
  const script = document.createElement('script');
  script.id = scriptId;
  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  head.appendChild(script);

  (window as any).dataLayer = (window as any).dataLayer || [];

  const isIframed = window.self !== window.top;

  window.gtag('js', new Date());

  window.gtag('config', trackingId, {
    ...(isIframed ? { cookie_prefix: GA_LOCAL_STORAGE_KEY } : {})
  });

  if (isIframed) {
    window.gtag('set', { cookie_flags: 'SameSite=None;Secure' });
  }
};

export const initializeGoogleAnalytics = (trackingId:string) => {

  _installGA(trackingId);

  if ((window as any).dataLayer) _isInitialized = true;

  if (googleQueue.length > 0) {
    googleQueue.forEach(call => {
      switch (call.type) {
        case GA_TYPES.PAGE:
          trackGooglePage((call as GooglePageObject).payload);
          break;
        case GA_TYPES.EVENT:
          const { category, action, label } = (call as GoogleEventObject).payload;
          trackGoogleEvent(category, action, label);
          break;
        default:
          // do nothing
      }
    });

    googleQueue = [];
  }
};

export const isInitialized = () => {
  return _isInitialized;
}

export const trackGoogleEvent = (category:string, action:string, label:string) => {
  if (_isInitialized) {
    window.gtag('event', action, {'event_category': category, 'event_label': label});
  } else {
    googleQueue.push({
      payload: { category, action, label },
      type: GA_TYPES.EVENT
    });
  }
};

export const trackGooglePage = (page:string) => {
  if (_isInitialized) {
    window.gtag('event', GA_TYPES.PAGE, {
      page_title: page
    });
  } else {
    googleQueue.push({
      payload: page,
      type: GA_TYPES.PAGE
    });
  }
};
