import React, { useState, useEffect } from 'react';
import styles from './landing.module.css';
import { isTrue } from '@telescope/utility-helpers';
import api, { RESPONSE_CODES } from '../../util/api';
import { trackGoogleEvent, trackGooglePage } from '../../util/google-helpers';
import {COOKIES_LOGGED_USER, EMAIL_REGEX, GA_EVENTS} from '../../util/constants';
import { createCookie } from '../../util/helpers';

function Landing(props) {
  const optinsList = props.copy.optins.filter( optin => isTrue(optin.display) );
  const requiredOptins = optinsList.filter( optin => isTrue(optin.required) );
  const settings = props.appSettings;
  const setUserData = props.setUserData;

  const [userEmail, setUserEmail] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [optins, setOptins] = useState({});
  const [validApiResponse, setValidApiResponse] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  //Form validation
  useEffect( () => {
    const allOptinsChecked = requiredOptins.every( (optin) => {
      const name = optin.id;
      return optins[name] && isTrue(optin.required);
    });

    const isEmailValid = EMAIL_REGEX.test(userEmail);

    setEmailValid(isEmailValid);
    setSubmitEnabled(userEmail !== '' && isEmailValid && allOptinsChecked && validApiResponse);
  }, [userEmail, optins, props.copy.invalid_code_error, requiredOptins, validApiResponse]);

  //Update optin state for form validation
  function handleOptinClick(e) {
    const id = e.target.name;
    const checked = e.target.checked;
    setOptins( { ...optins, [id]: checked } );
  }

  // Update email state, and reset Api Response state
  function handleEmailChange(e) {
    setUserEmail(e.target.value);

    setValidApiResponse(true);
  }

  //Submit the form!
  function handleFormSubmit(e) {
    const apiKey = settings.apiKey;
    const versionId = settings.version_id;

    e.preventDefault();

    api.postToVoteApi({
      apiKey,
      timestamp: Date.now(),
      user_id: userEmail,
      ...optins,
      action_type: 'login'
    }, versionId).then(response => {

      if (response.response_code === RESPONSE_CODES.VALID) {
        const { category, action, label } = GA_EVENTS.EMAIL_SUBMIT_SUCCESS;
        trackGoogleEvent( category, action, label );
        setUserData(true);
        createCookie(COOKIES_LOGGED_USER, "true");
      } else {

        const { category, action, label } = GA_EVENTS.EMAIL_SUBMIT_ERROR;
        trackGoogleEvent( category, action, label );

        switch (response.response_code) {
          case RESPONSE_CODES.OUT_OF_WINDOW:
            setErrorMessage(props.copy.window_error);
            break;
          default:
          case RESPONSE_CODES.GENERAL_INVALID:
            setErrorMessage(props.copy.general_invalid_error);
            break;
        }
      }

      setValidApiResponse( response.response_code === RESPONSE_CODES.VALID );
    });
  }

  const navigateTo = (id) => {
    const { category, action } = GA_EVENTS.ROUTING_CLICK;
    trackGoogleEvent( category, action, id );
    props.navigator(id);
  };

  useEffect(() => {
    trackGooglePage('Access Login Page');
  }, []);

  return (
    <div className={ styles.landing }>
      <form method="post" onSubmit={ handleFormSubmit } className={ !validApiResponse ? styles.error : '' }>
        <h1 className={ styles.headline }>{ props.copy.headline }</h1>
        <p className={ styles.subheadline } dangerouslySetInnerHTML={{ __html: props.copy.subheadline }} />

        <div className={ styles.optins }>
        { optinsList.map(optin => {
          if( isTrue(optin.display) ) {
            const hasOptinError = optins && optins[optin.id] === false && isTrue(optin.required);
            let optinLink;
            if ( optin.internal_id !== '') {
              optinLink = (<span onClick={()=> navigateTo(optin.internal_id)}>{optin.label_link}</span>);
            } else if ( optin.external_link !== '') {
              optinLink = (<a href={optin.external_link} target="_blank" rel="noopener noreferrer">{optin.label_link}</a>);
            }
            return (
              <div className={ styles.optin_group } key={ optin.id }>
                <input type="checkbox"
                      name={ optin.id }
                      id={ optin.id }
                      required={ isTrue(optin.required) }
                      onChange={ handleOptinClick }
                      className={ hasOptinError ? styles['optin-error'] : null } />
                <label htmlFor={ optin.id } className={ styles['optin-label'] }>
                  <span>{optin.label}</span><span className={ styles['optin-link']}>{optinLink}</span>
                </label>
                { hasOptinError &&
                  <p className={ styles.input_error }>{ optin.error }</p> }
              </div>
            )
          }
          return false;
        })}
        </div>

        <div className={ styles.email_group }>
          <input type="email"
                 placeholder={props.copy.input_placeholder}
                 name="email"
                 className={ styles['input-email'] }
                 maxLength="60"
                 onChange={ handleEmailChange } />

          { userEmail !== '' && !emailValid && <p className={ styles.input_error }>{ props.copy.input_error }</p>}
        </div>

        { errorMessage && <p className={ styles.errorMessage }>{ errorMessage }</p>}
        <input type="submit"
              name="submit"
              className={ styles.submit }
              value={ props.copy.submit_button }
              disabled={ !submitEnabled } />

      </form>

      { props.copy.help.length > 0 &&
      <p className={ styles.help } dangerouslySetInnerHTML={{ __html: props.copy.help }} /> }

    </div>
  );
}

export default Landing;
